<template>
  <MoreMenuButton
    :label="label"
    :groups="groups"
    position="bottom"
    :color="color"
    :customRightMargin="rightMargin"
    :primary="includeInProgress"
    :small="small"
  >
    <v-layout align-center>
      {{ label }}
      <v-icon class="ml-1" small>mdi-chevron-down</v-icon>
    </v-layout>
  </MoreMenuButton>
</template>

<script>
const IN_PROGRESS = 0;
const CARRIED = 1;
const UNCARRIED = 2;
const TERMINATED = 3;

export default {
  props: {
    label: {},
    rightMargin: {
      type: String,
      default: "0px",
    },
    includeInProgress: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      color: "primary",
    },
    isChild: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    groups() {
      return [
        {
          items: [
            {
              title: "W trakcie",
              action: () => this.onSelect(IN_PROGRESS),
              visible: this.includeInProgress,
            },
            {
              title: this.isChild ? "Ciąża donoszona" : "Donoszona",
              action: () => this.onSelect(CARRIED),
              visible: true,
            },
            {
              title: this.isChild ? "Ciąża niedonoszona" : "Niedonoszona",
              action: () => this.onSelect(UNCARRIED),
              visible: true,
            },
            {
              title: this.isChild ? "Ciąża terminowana" : "Terminowana",
              action: () => this.onSelect(TERMINATED),
              visible: true,
            },
          ].filter((item) => item.visible === true),
        },
      ];
    },
  },
  components: {
    MoreMenuButton: () => import("@/components/menus/MoreMenuButton"),
  },
  methods: {
    onSelect(kind) {
      this.$emit("select", kind);
    },
  },
};
</script>